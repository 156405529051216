.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.dashboard-tabs {
  margin-top: 55px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.content-container {
  margin-top: 85px;
  flex: 1;
  overflow-y: auto;
  background-color: #F9F9F9;
  padding: 20px;
}