GLOBAL
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #ffffff;
  overflow: auto;
}

* {
  box-sizing: border-box;
}


.autocomplete-dropdown {
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 8px;
  cursor: pointer;
  box-shadow: 0px 5px 15px #22222214;
}

.location-search-input {
  font-size: 16px;
  opacity: 0.8;
}
.location-search-input::placeholder {
  font-size: 16px;
  opacity: 0.8;
}

.resourcesNavLink {
  text-decoration: none;
  padding: 13px 35px 13px 0px;
  position: relative;
  white-space: nowrap;
}

.resourcesNavLink .resourcesNavLinkText {
  opacity: 0.5;
  color: #000;
}

.resourcesNavLink.active .resourcesNavLinkText {
  opacity: 1;
  color: #000929;
}

.resourcesNavLink.active #borderBottom {
  border-bottom: 3px solid #000929;
  padding-bottom: 10px;
  position: absolute;
  right: 50%;
  left: 0;
}

.scrollBox::-webkit-scrollbar {
  display: none;
}

.mundialSemiBold {
  font-family: mundialSemiBold;
}

::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}

.filter_container::-webkit-scrollbar {
  width: 4px;
  background: lightgrey;
}

.filter_container::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

input::placeholder {
  font-family: mundial !important;
  font-size: 14px !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: mundial !important;
}

.ql-editor {
  min-height: 150px;
  font-family: mundial !important;
}

.SnackbarItem-message {
  font-family: mundial !important;
}

.fc-toolbar-title {
  color: #182f53;
  font-size: 25px !important;
}

.fc-button-group button {
  text-transform: capitalize !important;
}

.fc-today-button {
  text-transform: capitalize !important;
}

.mentions_input {
  font-family: mundial !important;
}

.mentions_input__highlighter {
  height: auto;
  min-height: 60px;
  padding: 10px;
  padding-right: 50px;
  transition: height 0.3s;
}

.focused_mentions_input .mentions_input__highlighter {
  min-height: 160px;
}

.mentions_input textarea {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  padding: 10px;
  padding-right: 50px;
}

.mentions_input__suggestions {
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  background: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(20px);
}

.mentions_input__suggestions__item {
  padding: 5px;
  font-size: 14px;
}

.mentions_input__suggestions__item--focused {
  background-color: rgba(0, 0, 0, 0.1);
}




.Toastify__toast {
  font-family: mundial !important;
}

.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 15px !important;
  background: #fae1e2 !important;
}

.Toastify__toast--success {
  border: 1px solid #0a4910 !important;
  border-radius: 15px !important;
  background: #dff9c8df !important;
}
